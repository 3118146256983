/* styles.css */

.block-head {
  margin-bottom: 20px;
  clear: both;
  padding: 20px 0;
  background-color: #f6f7f7;
}

@media only screen and (max-width: 768px) {
  .block-head {
    margin-top: 65px;
  }
}


.block-head-lg {
  font-size: 24px;
}

.block-head-wide-xs {
  max-width: 100%;
}


.block-title {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  margin-bottom: 5px;
  color: #404040;
  font-weight: 700;
}

.block-des {
  margin-bottom: 20px;
}

.block {
  margin-bottom: 40px;
}

.preview-card {
  font-family: "Poppins", sans-serif;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
}

.card-inner-xl {
  padding: 40px;
}

/* styles.css */
.breadcrumb {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  padding: 0;
  list-style-type: none;
  display: flex; /* Make the breadcrumb a flex page-container */
  justify-content: center; /* Center the items within the page-container */
}

/* Rest of your breadcrumb styles */
/* Base styles for breadcrumb */
.breadcrumb {
  font-family: "Poppins", sans-serif; /* Adjust font family as needed */
  background-color: transparent;
  padding: 8px 16px; 
  list-style-type: none;
  margin-bottom: 0; 
}

/* Breadcrumb items */
.breadcrumb-item {
  display: inline-block;
}

/* Separator between items */
.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding: 5 5px;
  color: #ccc; /* Adjust separator color */
}

/* Link styles within breadcrumb */
.breadcrumb-item a {
  color: #800020; /* Adjust link color */
  text-decoration: none;
}

/* Hover effect for links */
.breadcrumb-item a:hover {
  text-decoration: underline;
}

/* Active breadcrumb item */
.breadcrumb-item.active {
  color: #800020; /* Adjust active item color */
  text-decoration: none; /* Remove underline for active item */
}

.page-container p {
  color: #333;
}
.page-container .bullet-list {
  color: #404040;
}
.page-container .bullet-point {
  color: #404040;
}
.sub-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #6D7B8E;
}

.bullet-list {
  list-style-type: none; /* Remove default bullets */
}

.bullet-list li {
  position: relative;
  padding-left: 20px; /* Space for custom bullet */
}
.bullet-point {
  list-style-type: none; /* Remove default bullets */
}

.bullet-point li {
  position: relative;
  margin-bottom: 10px;
}


.bullet-point li li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.bullet-point li li::before {
  content: '•'; /* Custom bullet character */
  position: absolute;
  left: 0;
  color: #800020; /* Custom color */
  padding-left: 10px;
}
.bullet-list li::before {
  content: '•'; /* Custom bullet character */
  position: absolute;
  left: 0;
  color: #800020; /* Custom color */
}

.detail {
  font-style: italic;
  text-align: center;
  color: #800020;
}



.list li {
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.5rem;
}
.list li:not(:last-child) {
  padding-bottom: 0.5rem;
}
.list li:before {
  position: absolute;
  left: 0;
  font-size: 14px;
  line-height: 1.5rem;
  font-family: 'Material Symbols Outlined';
  src: url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200') format('woff2');
  content: "spinner";
}
.list li span {
  color: #8094ae;
}
.list li ul {
  margin-top: 0.5rem;
}
.list-checked li:before {
  color: #810020;
  content: "\e86c";
  font-size: 30px;
}
.list-checked-circle li:before {
  color: #810020;
  content: "\e9b7";
}
.list-cross li:before {
  color: rgba(232, 83, 71, 0.8);
  content: "\ea02";
}
.list-status {
  list-style: none;
  line-height: 1.3;
}
.list-status li {
  display: inline-flex;
  align-items: center;
  padding-right: 1rem;
  vertical-align: middle;
}
.list-status .icon {
  margin-right: 0.375rem;
  line-height: 1.3;
}
.list-lg li {
  padding-left: 2rem;
}
.list-lg li:not(:last-child) {
  padding-bottom: 0.75rem;
}
.list-lg li:before {
  font-size: 1.35rem;
}
.list-sm li {
  padding-left: 1.2rem;
}
.list-sm li:not(:last-child) {
  padding-bottom: 0.35rem;
}
.list-sm li:before {
  font-size: 12px;
}
.list-step li {
  color: #000;
  font-weight: 500;
}

/* .list-step li a{
  color: #000;
} */

.list-step li:before {
  content: "\e86c";
  font-size: 20px;
}
.list-step li.list-step-current {
  color: #810020;
}
.list-step li.list-step-current:before {
  content: "\e000";
  color: #810020;
}
.list-step li.list-step-done {
  color: #000;
}
.list-step li.list-step-done:before {
  content: "\e86c";
  color: #1ee0ac;
}


.list-step li.list-step-pending {
  content: "\ef64";
  color: #8094ae;
}

.list-step li.list-step-pending:before {
  content: "\ef64";
  color: #8094ae;
}

.list-plain a {
  color: #526484;
  display: block;
  line-height: 1.3;
  padding: 0.5rem 0;
}
.list-plain a:hover {
  color: #810020;
}
.list-category a {
  font-weight: 500;
}
.list-download li > a {
  display: inline-block;
  padding: 0.25rem;
}
.list-download li > a > .icon {
  margin: 0 0.25rem;
  font-size: 1.25rem;
}

.list-primary li:before {
  color: #810020;
}

.list-secondary li:before {
  color: #364a63;
}

.list-success li:before {
  color: #1ee0ac;
}

.list-info li:before {
  color: #9E0033;
}

.list-warning li:before {
  color: #f4bd0e;
}

.list-danger li:before {
  color: #e85347;
}

.list-light li:before {
  color: #e5e9f2;
}

.list-dark li:before {
  color: #1f2b3a;
}

.list-gray li:before {
  color: #8091a7;
}

.list-lighter li:before {
  color: #f5f6fa;
}
.accordion-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  font-size: 1rem;
  color: #364a63;
  transform: translateY(-50%);
  transition: rotate 0.4s;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.accordion-icon:before {
  content: "\e313";
  font-family: 'Material Symbols Outlined';
  src: url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200') format('woff2');
  transition: 0.4s;
}
.accordion-head.collapsed .accordion-icon:before {
  content: "\e313";
}


.page-container,
.page-container-fluid,
.page-container-xxl,
.page-container-xl,
.page-container-lg,
.page-container-md,
.page-container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 14px);
  padding-left: var(--bs-gutter-x, 14px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .page-container-sm, .page-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .page-container-md, .page-container-sm, .page-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .page-container-lg, .page-container-md, .page-container-sm, .page-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .page-container-xl, .page-container-lg, .page-container-md, .page-container-sm, .page-container {
    max-width: 1140px;
  }
}
@media (min-width: 1540px) {
  .page-container-xxl, .page-container-xl, .page-container-lg, .page-container-md, .page-container-sm, .page-container {
    max-width: 1440px;
  }
}


.page {
  font-family: "Poppins", sans-serif;
  color: #333;
    
 
  }
  .div {
    margin-bottom: '20px';
  }
  .header {
    font-family: 'Poppins, sans-serif';
    color: '#404040';
   
  }
   /* Legal Page*/
  .legal-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    margin: auto;
    max-width: 800px;
    color: #333;
  }
  
  .legal-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .legal-header h1 {
    font-size: 2.5rem;
    color: #800020;
  }
  
  .legal-header p {
    font-size: 1rem;
    color: #555;
  }
  
  .legal-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .legal-link {
    text-decoration: none;
    font-size: 1.2rem;
    color: #800020;
    border: 1px solid #333;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .legal-link:hover {
    background-color: #800020;
    color: white;
  }
  @media (max-width: 600px) {
    .legal-links {
      grid-template-columns: 1fr;
    }
  }

 

  /* Address Cards Container */
.entry .address-container {
  display: flex;
  gap: 1.5em;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.entry .address-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5em;
  flex: 1 1 calc(50% - 1.5em);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s, box-shadow 0.3s;
}

.entry .address-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.entry .address-card h3 {
  font-size: 1.2em;
  color: #800020;
  margin-bottom: 0.5em;
}

.entry .address-card p {
  margin: 0.2em 0;
  font-size: 0.95em;
  color: #555;
}

.entry .address-card strong {
  font-weight: 600;
  color: #002244;
}

/* Responsive Design */
@media (max-width: 768px) {
  .entry .address-card {
    flex: 1 1 100%;
  }
}

/* Follow Us Section */
.follow-us-container {
  margin-top: 2em;
  text-align: center;
}

.follow-us-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
}

.follow-us-button {
  display: inline-block;
  padding: 0.8em 1.5em;
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
  background-color: #0056b3;
  border: none;
  border-radius: 25px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.follow-us-button:hover {
  background-color: #003d80;
  transform: translateY(-3px);
}

.follow-us-button:active {
  background-color: #002a57;
  transform: translateY(1px);
}

.follow-us-button.twitter { background-color: #1da1f2; }
.follow-us-button.linkedin { background-color: #0077b5; }
.follow-us-button.facebook { background-color: #1877f2; }
.follow-us-button.instagram { background-color: #e1306c; }
.follow-us-button.tiktok { background-color: #000000; }
.follow-us-button.youtube { background-color: #ff0000; }

/* Customer Support Cards */
.support-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5em;
  margin-top: 2em;
}

.support-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  padding: 1.5em;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.support-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.support-card h3 {
  font-size: 1.25em;
  color: #333;
  margin-bottom: 1em;
}

.support-card p {
  font-size: 1em;
  color: #555;
  margin-bottom: 1.2em;
}

.support-card a {
  display: inline-block;
  color: #800020;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.support-card a:hover {
  color: #a4052dfe;
}

/* Add icons to the card */
.support-card i {
  font-size: 2em;
  color: #800020;
  margin-bottom: 1em;
}

/* General Styles for the Section */


.visit-us-container .sub-title {
  margin-top: 2em;
  text-align: center;
}

.visit-us-container p {
  font-size: 1em;
  color: #555;
  margin-bottom: 1.5em;
  line-height: 1.6;
  text-align: center;
}

/* Parent container for the image and text */
.intro-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem; /* Space between the image and text */
  margin-bottom: 2em; /* Spacing below the section */
  flex-wrap: wrap; /* Stacks content for smaller screens */
}

/* Styling for the image */
.intro-image {
  max-width: 40%; /* Adjust the size of the image */
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Styling for the text */
.intro-text {
  flex: 1; /* Allow the text to take up the remaining space */
}

.intro-text p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #333;
  margin: 0; /* Reset margin for clean alignment */
}

/* Responsive Design */
@media (max-width: 768px) {
  .intro-section {
    flex-direction: column; /* Stack the image and text */
    align-items: center; /* Center content on smaller screens */
  }

  .intro-image {
    max-width: 100%; /* Image takes full width */
    margin-bottom: 1em; /* Space below the image */
  }

  .intro-text {
    text-align: center; /* Center-align text */
  }
}

/* Parent container for centering */
.centered-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Horizontally center content */
  justify-content: center; /* Vertically center content */
  text-align: center; /* Center text alignment */
}

/* Styling for the title */
.centered-content .sub-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem; /* Space below the title */
}


/* Company Page */
.company-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Hero Section */
.hero-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #800020;
  color: white;
  padding: 40px;
  border-radius: 8px;
  gap: 20px; /* Add spacing for flex items */
}

.hero-content {
  flex: 1;
  max-width: 50%;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin: 0;
}

.hero-content p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.hero-button {
  display: inline-block;
  padding: 10px 20px;
  background: white;
  color: #800020;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
}

.hero-image {
  flex: 1;
  max-width: 40%;
}

.hero-image img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
}



.about-section,
.how-we-work,
.impact-section,
.resources-section {
  text-align: center;
}

.about-image img {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

/* Work Steps */
.work-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.step {
  flex: 1 1 250px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.step img {
  width: 60px;
  margin-bottom: 15px;
}

/* Impact Section */
.impact-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.stat {
  flex: 1 1 200px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

/* Resources Section */
.resources {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.resource-item {
  flex: 1 1 300px;
  background: white;
  padding: 20px;
  border-radius: 8px;
}

/* CTA Section */
.cta-section {
  text-align: center;
  background: #800020;
  color: white;
  padding: 40px;
  border-radius: 8px;
  margin-top: 40px;
}

.cta-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background: white;
  color: #800020;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
}

.cta-button:hover {
  background: #e6e6e6;
}

/* Responsive Styling */
@media (max-width: 768px) {


  .hero-content h1 {
    font-size: 2rem; /* Adjust font size */
  }

  .hero-image {
    max-width: 100%;
    margin-top: 20px; /* Add spacing between content and image */
  }

  
  /* Work Steps and Resources */
  .work-steps,
  .impact-stats,
  .resources {
    gap: 10px; /* Narrower gap between items */
  }
}

/* About */

.about-header {
  background-image: url('https://picsum.photos/seed/picsum/500/300');
  background-size: cover;
  background-position: center;
  color: #fff;
}

.about-sub-title {
  color: #343a40;
  margin-bottom: 15px;
  font-size: 1.8rem;
}

.about-bullet-point {
  list-style-type: none;
  padding: 0;
}

.about-bullet-point li {
  margin: 10px 0;
  font-size: 1rem;
}

.about-bullet-point li::before {
  content: '•';
  color: #800020;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}
