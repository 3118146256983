
body {
  font-family: "Poppins", sans-serif;
  color: #444444;
}

a {
  color: #810020;
  text-decoration: none;
}

a:hover {
  color: #810020ce;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}


.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #810020;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #810020ce;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #810020;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}


.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  color: #810020;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #810020;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #810020;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #820927;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #810020;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}


.mobile-nav-toggle {
  color: #810020;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(10, 38, 58, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #810020;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #810020;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #810020;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}



#hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: #F5F7F8;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 80px;
}

#hero h1 {
  margin: 0;
  font-size: 46px;
  font-weight: 700;
  line-height: 70px;
  color: #810020;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

#hero .btn-get-started {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 8px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #810020;
}

#hero .btn-get-started:hover {
  background: #ca0435;
}

#hero .icon-boxes {
  margin-top: 80px;
}

#hero .icon-box {
  padding: 20px 20px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

#hero .icon-box .title {
  
  margin-bottom: 10px;
  font-size: 16px;
}

#hero .icon-box .title a {
  color: #810020;
  transition: 0.3s;
  text-align: justify;
}

#hero .icon-box .description {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0;
  text-align: justify;
}

#hero .icon-box .icon {
  margin-bottom: 10px;
  padding-top: 5px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 30px;
  line-height: 1;
  color: #810020;
  
}

#hero .icon-box:hover {
  transform: scale(1.08);
}

#hero .icon-box:hover .title a {
  color: #810020;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}


section {
  padding: 50px 0;
  overflow: hidden;
}
.carousel {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1px 0 1px 0;
  overflow: hidden;
}
.carousel {
  background-color: #ffffff;
}
.section-bg {
  background-color: #f8fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #810020;
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}


.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list span {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #87c1ea;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list span.collapsed {
  color: #343a40;
}

.faq .faq-list span.collapsed:hover {
  color: #810020;
}

.faq .faq-list span.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list span.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}




#footer {
  color: #444444;
  font-size: 14px;
  background: #EAEAEA;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#footer .footer-top {
  padding: 40px 0 30px 0;
  background: #F8F8F8;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  color: #800020;
  position: relative;
  padding-bottom: 12px;
  
}

#footer .footer-top .footer-links {
  margin-bottom: 10px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #810020;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #810020;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  position: center;
  color: #800020;
  padding: 8px 8px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
}

#footer .social-links a:hover {
  color: #575757dc;
  text-decoration: none;
}



@media (max-width: 767px) {
  #footer .container.align-horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  
  #footer .container.align-horizontal .footer-links {
    width: 50%;
  }
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 9999; 
}

 
.loader-container img {

 width: 30%;  
 height: 30%;  
 object-fit: contain;  
}




.slick-slide {
            margin: 0px 10px;
        }

        .slick-slide img {
            width: 40px;
        }

        .slick-slider {
            position: relative;
            display: block;
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
        }

        .slick-list {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }

        .slick-list:focus {
            outline: none;
        }

        .slick-list.dragging {
            cursor: pointer;
            cursor: hand;
        }

        .slick-slider .slick-track,
        .slick-slider .slick-list {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
        }

        .slick-track:before,
        .slick-track:after {
            display: table;
            content: '';
        }

        .slick-track:after {
            clear: both;
        }

        .slick-loading .slick-track {
            visibility: hidden;
        }

        .slick-slide {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
        }

        [dir='rtl'] .slick-slide {
            float: right;
        }

        .slick-slide img {
            display: block;
        }

        .slick-slide.slick-loading img {
            display: none;
        }

        .slick-slide.dragging img {
            pointer-events: none;
        }

        .slick-initialized .slick-slide {
            display: block;
        }

        .slick-loading .slick-slide {
            visibility: hidden;
        }

        .slick-vertical .slick-slide {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }

        .slick-arrow.slick-hidden {
            display: none;
        }
       
        .service-container {

            width: 500px;
            height: 300px;
            perspective: 900px;
            margin-top: 20px;
        }

        .card {
            height: 100%;
            width: 100%;
            background-color: aliceblue;
            position: relative;
            transition: transform 1500ms;
            transform-style: preserve-3d;
            border-radius: 2rem;
        }

        .service-container:hover>.card {
            cursor: pointer;
            transform: rotateY(180deg) rotateZ(180deg);
        }

        .front,
        .back {
            height: 100%;
            width: 100%;
            border-radius: 1rem;
            position: absolute;
            box-shadow: 0 0 10px 2px rgba(50, 50, 50, 2.5);
            backface-visibility: hidden;
            color: aliceblue;
            background: linear-gradient(-135deg,
                    #810020, #111419);
        }

        .front,
        .back {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }

        .back {
            transform: rotateY(180deg) rotateZ(180deg);
        }

        .back-heading,
        .front-heading {
            font-size: 28px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', "Poppins", Oxygen, Ubuntu, Cantarell, 'Poppins', 'Helvetica Neue', sans-serif;
            font-weight: bold;
        }

        .back p {
            padding: 20px;
            text-align: center;
        }

        
        .services .row {
          display: flex;
          flex-wrap: wrap;
      }

      .services .col-lg-4 {
          flex: 1;
      }

      
      @media (max-width: 767px) {
          .services .col-lg-4 {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 20px;
              
          }
      }


    

      .slick-slide {
          margin: 0px 10px;
      }

      .slick-slide img {
          width: 40px;
      }

      .slick-slider {
          position: relative;
          display: block;
          box-sizing: border-box;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-touch-callout: none;
          -khtml-user-select: none;
          -ms-touch-action: pan-y;
          touch-action: pan-y;
          -webkit-tap-highlight-color: transparent;
      }

      .slick-list {
          position: relative;
          display: block;
          overflow: hidden;
          margin: 0;
          padding: 0;
      }

      .slick-list:focus {
          outline: none;
      }

      .slick-list.dragging {
          cursor: pointer;
          cursor: hand;
      }

      .slick-slider .slick-track,
      .slick-slider .slick-list {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
      }

      .slick-track {
          position: relative;
          top: 0;
          left: 0;
          display: block;
      }

      .slick-track:before,
      .slick-track:after {
          display: table;
          content: '';
      }

      .slick-track:after {
          clear: both;
      }

      .slick-loading .slick-track {
          visibility: hidden;
      }

      .slick-slide {
          display: none;
          float: left;
          height: 100%;
          min-height: 1px;
      }

      [dir='rtl'] .slick-slide {
          float: right;
      }

      .slick-slide img {
          display: block;
      }

      .slick-slide.slick-loading img {
          display: none;
      }

      .slick-slide.dragging img {
          pointer-events: none;
      }

      .slick-initialized .slick-slide {
          display: block;
      }

      .slick-loading .slick-slide {
          visibility: hidden;
      }

      .slick-vertical .slick-slide {
          display: block;
          height: auto;
          border: 1px solid transparent;
      }

      .slick-arrow.slick-hidden {
          display: none;
      }


      .service-container {

          width: 500px;
          height: 200px;
          perspective: 900px;
          margin-top: 20px;
      }

      .card {
          height: 100%;
          width: 100%;
          background-color: aliceblue;
          position: relative;
          transition: transform 1500ms;
          transform-style: preserve-3d;
          border-radius: 2rem;
      }

      .service-container:hover>.card {
          cursor: pointer;
          transform: rotateY(180deg) rotateZ(180deg);
      }

      .front,
      .back {
          height: 100%;
          width: 100%;
          border-radius: 1rem;
          position: absolute;
          box-shadow: 0 0 3px 1px rgba(50, 50, 50, 2.5);
          backface-visibility: hidden;
          color: aliceblue;
          background: linear-gradient(-135deg,
                  #810020, #111419);
      }

      .front,
      .back {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 10px;
      }

      .back {
          transform: rotateY(180deg) rotateZ(180deg);
      }

      .back-heading,
      .front-heading {
          font-size: 28px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', "Poppins", Oxygen, Ubuntu, Cantarell, 'Poppins', 'Helvetica Neue', sans-serif;
          font-weight: bold;
      }

      .back img {
        height: 100%;
          width: 100%;
          border-radius: 1rem;
          position: absolute;

      }



    
        
